import '../styles/globals.css'
import { ChakraProvider } from '@chakra-ui/react'
import * as Sentry from '@sentry/nextjs'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { register } from 'timeago.js'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { s3ImageURL } from '@miimosa/common'
import { timeagoFR } from '@miimosa/design-system/lib/i18n/timeago'

import { theme } from '../themes'

import Layout from '@components/Layout'

// register your locale with timeago
register('fr', timeagoFR)

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="icon" type="image/png" href="/favicon.png" sizes="64x64" />
        <title>Lesieur s&apos;engage pour la transition environnementale et agroalimentaire</title>
        <meta
          name="description"
          content='Avec MiiMOSA, Lesieur soutient et accompagne les agriculteurs partenaires de la démarche "Lesieur Huiles Engagées" pour leur financement de projets en faveur de la transition alimentaire et environnementale'
        />
        <meta
          property="og:title"
          content="Lesieur s’engage pour la transition environnementale et agroalimentaire"
          key="title"
        />
        <meta
          property="og:description"
          content='Avec MiiMOSA, Lesieur soutient et accompagne les agriculteurs partenaires de la démarche "Lesieur Huiles Engagées" pour leur financement de projets en faveur de la transition alimentaire et environnementale'
        />
        <meta property="og:image" content={s3ImageURL('tournesol-meta.jpg')} key="image" />
      </Head>
      <Sentry.ErrorBoundary showDialog={false}>
        <ChakraProvider theme={theme}>
          <Layout>{<Component {...pageProps} />}</Layout>
        </ChakraProvider>
      </Sentry.ErrorBoundary>
    </>
  )
}
