import { SystemStyleObject } from '@chakra-ui/react'

const sizes: Record<string, SystemStyleObject> = {
  sm: {
    fontSize: '0.688rem', //11px
    lineHeight: '1.2rem',
  },
  md: {
    fontSize: '0.875rem', //14px
    lineHeight: '1.4rem',
  },
  lg: {
    fontSize: '1rem', //16px
    lineHeight: '1.5rem',
  },
  xl: {
    fontSize: '1.125rem', //18px
    lineHeight: '1.575rem',
  },
}

export const Text = {
  sizes,
  // Outdated, use the color attribute directly instead
  variants: {},
  // The default size and variant values
  defaultProps: {
    size: 'md',
    fontWeight: '400',
    color: 'black',
  },
}
