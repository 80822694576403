import { theme as baseTheme, defineStyle, extendTheme } from '@chakra-ui/react'
import { Raleway } from 'next/font/google'
import localFont from 'next/font/local'

import { Heading } from './heading'
import { Text } from './text'

const Argone = localFont({
  src: [
    {
      path: '../../public/fonts/ArgoneRegular.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/fonts/ArgoneLight.ttf',
      weight: '200',
      style: 'normal',
    },
    {
      path: '../../public/fonts/ArgoneBold.ttf',
      weight: '700',
      style: 'normal',
    },
  ],
  display: 'swap',
})

const raleway = Raleway({
  weight: '400',
  subsets: ['latin'],
  display: 'swap',
})

// Breakpoints
// https://chakra-ui.com/docs/features/responsive-styles
// Chakra breakpoints are:
// sm: "30em" = 480px
// md: "48em" = 768px
// lg: "62em" = 992px
// xl: "80em" = 1280px
// "2xl": "96em" = 1536px

// Making sure chakra base sizes are not present
baseTheme.components.Heading.sizes = undefined
baseTheme.components.Input.sizes = undefined
baseTheme.components.Avatar.sizes = undefined

const solid = defineStyle({
  _hover: {
    bg: 'null',
    _disabled: {
      bg: 'null',
    },
  },
  _active: {
    bg: 'null',
  },
})

const Button = {
  variants: {
    solid,
  },
}

export const theme = extendTheme({
  components: {
    Text,
    Heading,
    Button,
  },
  breakpoints: {
    '3xl': '106em',
    '4xl': '120em',
  },
  styles: {
    global: {
      html: {
        scrollBehavior: 'smooth',
      },
      'html, body': {
        color: 'black',
      },
    },
  },
  fonts: {
    heading: `${Argone.style.fontFamily}, ${baseTheme.fonts.heading}`,
    body: `${raleway.style.fontFamily}, ${baseTheme.fonts.body}`,
  },
  // Thes font size should not be used
  // Use the component's sizes instead
  lineHeights: {
    md: '1,4rem',
  },
  colors: {
    red: '#d50b11',
    green: {
      200: '#95bc47',
      800: '#00694e',
    },
    orange: {
      200: '#f3c34f',
      800: '#d0681e',
    },
    blue: '#4065ab',
    // gray: '#848080',
    // grey: '#848080',
    gray: '#4a4848',
    grey: '#4a4848',
    cream: '#e6dac6',
    radio: { 500: '#000' },
  },
})
